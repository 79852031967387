import React from 'react';
import AdSlot from './AdSlot';
import { InStreamAd } from './Styles';

interface ProductHomePageInStreamProps {
  id: number;
}

const ProductHomePageInStream: React.FC<ProductHomePageInStreamProps> = ({ id }: ProductHomePageInStreamProps) => (
  <InStreamAd className='simple-homepage'>
    <AdSlot
      placementName='InContent'
      index={id + 1}
      slotId={`InStream${id}`}
    />
  </InStreamAd>
);

export default ProductHomePageInStream;
